let burger = document.querySelector(".js-menu__btn"),
    menu = document.querySelector(".js-aside"),
    burgerClose = document.querySelector(".js-menu__close"),
    headerOverlay = document.querySelector(".js-header-overlay"),
    body = document.querySelector("body"),
    header = document.querySelector(".js-header");
window.matchMedia("(max-width: 1400px)").matches &&
    burger.addEventListener("click", function () {
        menu.classList.toggle("is-active"), headerOverlay.classList.toggle("is-active"), body.classList.toggle("no-scroll");
    }),
    headerOverlay.addEventListener("click", function (e) {
        e.target.className === headerOverlay.className &&
            (menu.classList.remove("is-active"), headerOverlay.classList.remove("is-active"), body.classList.remove("no-scroll"), body.classList.remove("move"), burger.classList.remove("none"), header.classList.remove("move"));
    }),
    burgerClose.addEventListener("click", function () {
        menu.classList.remove("is-active"), menu.classList.toggle("is-open"), headerOverlay.classList.remove("is-active"), body.classList.remove("no-scroll"), header.classList.toggle("move"), body.classList.toggle("move");
    }),
    window.matchMedia("(min-width: 1400px)").matches &&
        burger.addEventListener("click", function () {
            headerOverlay.classList.remove("is-active"), body.classList.remove("no-scroll"), menu.classList.toggle("is-active"), header.classList.toggle("move"), body.classList.toggle("move");
        });
